// 审核按钮
<template>
  <v-row
    v-if="show"
  >
    <v-col
      md="12"
      class="text-center"
    >
      <v-divider
        class="mb-5"
      />
      <v-btn
        :large="isXs ? false : true"
        color="success"
        @click="adoptBtn"
      >
        通过
      </v-btn>
      <v-btn
        :large="isXs ? false : true"
        color="primary"
        @click="rejectBtn"
      >
        待完善
      </v-btn>
      <!-- <v-btn
        large
        color="warning"
        @click="auditDoubts"
      >
        存疑
      </v-btn> -->
    </v-col>
    <audioBox ref="audioBox" />
    <!-- 外商推送 -->
    <pushtracks
      ref="pushtracks"
      :parent-id="projectId"
      :form-id="formId"
      @pushInitChild="pushInitChild()"
    />
  </v-row>
</template>

<script>
  export default {
    components: {
      audioBox: () => import('../../../components/base/Audio'),
      pushtracks: () => import('./Pushtracks'),
    },
    props: {
      projectId: {
        type: String,
        default: null,
      },
      userId: {
        type: String,
        default: null,
      },
      formId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        show: false,
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        detailList: {},
        // 会商审核
        ishsEdit: false,
        // 会商表（共用）
        searchParame: {
          formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
          _from: 'public',
          page: 0,
        },
        orgValue: '',
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.ishsEdit = localStorage.ishsEdit === 'true' || localStorage.ishsEdit === true;
      if (this.projectId) {
        this.getProcesses()
        this.getProjectInfo()
      }
    },
    methods: {
      async pushInitChild () {
        // 更新下详情中的推送，步骤条
        await this.$emit('pushInit')
        this.show = false
      },
      //! 获取项目信息
      async getProjectInfo () {
        const resp = await this.$axios.get('/reports/' + this.projectId + '/detail')
        this.detailList = resp.data.data
        console.log('this.detailList', this.detailList);
      },
      //! 审核权限的判断与按钮的展示
      async getProcesses () {
        const processesData = await this.$axios.get(`/reports/${this.projectId}/processes`)
        const processes = processesData.data.data
        const meRoleData = await this.$axios.get('/me')
        const meRole = meRoleData.data.data.roles
        console.log('processes', processes);

        // 投资促进处负责人
        if (meRole.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ'
        // 开发区处
        } else if (meRole.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ'
        // 投资促进处
        } else if (meRole.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ'
        } else {
          this.orgValue = ''
        }

        for (const p of processes) {
          if (p.active === 1 && p.status === 0) {
            if (meRole.includes(p.roleValue)) {
              this.show = true
              break
            }
          }
        }
      },
      //! 审核项目判断
      async adoptBtn () {
        // 初审，终审第一次  审核+推送
        const resp = await this.getOpinion()
        if ((this.formId === this.wsFormId || this.formId === this.tjFormId) && !this.detailList.data.projectStatus2 && this.ishsEdit && resp.length <= 1) {
          // 出现驳回情况再次审核时传会商信息  进行编辑
          // if (resp.length && this.orgValue === 'ROLE_TZCJCNBYJ') {
          //   this.$refs.pushtracks.open('first', resp[0])
          // } else {
          //   this.$refs.pushtracks.open('first')
          // }
          // 终审意见默认不写会传 无
          if (resp.length === 1 && this.orgValue === 'ROLE_TZCJCFZRSHYJ' && !resp[0].tzcfzrOpinion) {
            this.$refs.pushtracks.open('first')
          } else if (!resp.length) {
            this.$refs.pushtracks.open('first')
          } else {
            // 会商意见终审已经填过，直接审核
            this.adoptBtn2()
          }
        } else {
          this.adoptBtn2()
        }
      },
      // 审核项目
      async adoptBtn2 () {
        this.$refs.audioBox.audioPlay()
        this.$swal({
          title: '你确定核查通过该项目吗？',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          animation: false,
        }).then((result) => {
          this.$refs.audioBox.audioPlay()
          if (result.value) {
            this.adopt()
          }
        })
      },
      async getOpinion () {
        const searchParame = this.searchParame
        searchParame.parentID = this.projectId
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        })
        const desserts = resp.data.data.content
        return desserts
      },
      //! 审核项目的实际动作
      adopt () {
        this.adoptMobel = false
        this.overlay = true
        this.$axios.put('/reports/' + this.projectId + '/audit', {
          message: '审核通过',
          status: '10',
        })
          .then(() => {
            this.overlay = false
            this.$refs.audioBox.audioPlay('success')
            this.Lightpush(this.projectId)
            this.OfflineAuditDoubts()
            this.$swal({
              title: '审核成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              animation: false,
            })
            this.projectStatus = false
            this.$router.go(-1)
          })
          .catch((result) => {
            this.overlay = false
            this.$refs.audioBox.audioPlay('error')
            this.$swal({
              title: '通过审核失败!',
              text: result.response.data.msg,
              icon: 'error',
              confirmButtonText: '确定',
            })
          })
      },
      //! 驳回项目
      rejectBtn () {
        this.$refs.audioBox.audioPlay()
        this.$swal({
          title: '你确定该项目需要待完善吗？',
          text: '待完善项目状态无法更改',
          input: 'textarea',
          animation: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showLoaderOnConfirm: true,
          inputPlaceholder: '请填写待完善理由',
          preConfirm: (content) => {
            if (content === '') {
              this.$refs.audioBox.audioPlay('error')
              this.$swal.showValidationMessage(
                '待完善理由不能为空！',
              )
            } else {
              return this.$axios.put(`/reports/${this.projectId}/audit`, {
                message: content,
                status: '-5',
                // 驳回上一级，但是驳回这个人的状态是0
                // denyType: '1',
              })
                .then(res => {
                  this.Lightpush(this.projectId)
                  this.$refs.audioBox.audioPlay('success')
                  return res
                })
                .catch(err => {
                  this.$refs.audioBox.audioPlay('error')
                  return err.response
                })
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
          .then((result) => {
            if (result.value) {
              if (result.value.status === 200) {
                this.$refs.audioBox.audioPlay('success')
                this.OfflineAuditDoubts()
                this.$swal({
                  title: '发布成功。',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                  animation: false,
                })
                this.$router.go(-1)
              } else {
                console.log(result)
                this.$refs.audioBox.audioPlay('error')
                this.$swal({
                  title: '发布失败！',
                  text: result.value.data.msg,
                  icon: 'error',
                  showConfirmButton: true,
                  confirmButtonText: '知道了',
                })
              }
            } else {
              this.$refs.audioBox.audioPlay()
            }
          })
      },
      //! 存疑项目
      auditDoubts () {
        this.$refs.audioBox.audioPlay()
        this.$swal({
          title: '请填写存疑原因',
          text: '项目存疑后可在存疑项目列表查看',
          input: 'text',
          animation: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showLoaderOnConfirm: true,
          inputPlaceholder: '请填写存疑原因',
          preConfirm: (content) => {
            if (content === '') {
              this.$refs.audioBox.audioPlay('error')
              this.$swal.showValidationMessage(
                '存疑原因不能为空！',
              )
            } else {
              return this.$axios.post('/audit-doubts', {
                reason: content,
                reportId: this.projectId,
              })
                .then(res => {
                  this.$refs.audioBox.audioPlay('success')
                  return res
                })
                .catch(err => {
                  this.$refs.audioBox.audioPlay('error')
                  return err.response
                })
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
          .then((result) => {
            if (result.value) {
              if (result.value.status === 200) {
                this.$refs.audioBox.audioPlay('success')
                this.$swal({
                  title: '添加存疑成功。',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                  animation: false,
                })
                this.$router.go(-1)
              } else {
                this.$refs.audioBox.audioPlay('error')
                this.$swal({
                  title: '添加存疑失败！',
                  text: result.value.data.msg,
                  icon: 'error',
                  showConfirmButton: true,
                  confirmButtonText: '知道了',
                })
              }
            } else {
              this.$refs.audioBox.audioPlay()
            }
          })
      },
      // 更新项目亮灯字段
      async Lightpush (id) {
        const resp = await this.$axios.patch(`/reports/${id}`, {
          projectLight: null,
        })
        console.log('resp', resp);
      },
      // 下线存疑项目
      async OfflineAuditDoubts () {
        const auditDoubts = await this.$axios.get('/audit-doubts', {
          params: {
            reportId: this.projectId,
          },
        })
        if (auditDoubts.data.data.length > 0) {
          const AuditDoubtsId = auditDoubts.data.data.length > 0 ? auditDoubts.data.data[0].id : false
          this.$axios.put(`/audit-doubts/${AuditDoubtsId}/offline`)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
